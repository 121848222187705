import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageSlide from "../../components/ImageSlide"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage:
            "url(/images/spaces/facility/barbecue/facility_barbecue_02.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">기타 공동시설</h1>

          <p className="page-subtitle">함께 사용할 수 있는 시설들이 있어요.</p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container scrollimation fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-media">
                <ImageSlide
                  imageList={[
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/etc/facility_microoven_01.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">2층 전자레인지</p>
                    </li>,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
